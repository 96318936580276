import client from "@/api/client";

const api = {
  // 存储仓-存储仓标签服务
  getPagedList: "/api/WarehouseService/StorageUserLabelChange/GetPagedList",
  // 存储仓-存储仓标签服务详情
  getStorageUserLabelChange: "/api/WarehouseService/StorageUserLabelChange/Get",
  // 存储仓-取消存储仓标签服务
  cancelStorageUserLabelChange: "/api/WarehouseService/StorageUserLabelChange/Cancel",
  // 存储仓-创建存储仓标签服务-仓库列表
  getInventoryWarehouseWithAdress: "/api/WarehouseService/Box/GetInventoryWarehouseWithAdress",
  // 存储仓-创建存储仓标签服务-箱列表(这个接口其他地方也有用到,改的时候搜一下)
  getUserSelectInventoryListPaged: "/api/WarehouseService/Box/GetUserSelectInventoryListPaged",
  // 存储仓-创建存储仓标签服务-创建标签任务（第一步）
  createFirstStep: "/api/WarehouseService/StorageUserLabelChange/FirstStep",
  // 存储仓-创建存储仓标签服务-获取第二步详情
  getSecondStep: "/api/WarehouseService/StorageUserLabelChange/GetSecond",
  // 存储仓-创建存储仓标签服务-上传标签及设置数量
  createSecondStep: "/api/WarehouseService/StorageUserLabelChange/SecondStep",
  // 存储仓-创建存储仓标签服务-获取第三步详情
  getThirdStep: "/api/WarehouseService/StorageUserLabelChange/GetThird",
  // 存储仓-创建存储仓标签服务-获取费用
  getFee: "/api/WarehouseService/StorageUserLabelChange/GetFee",
  // 存储仓-创建存储仓标签服务-确认并支付
  createThirdStep: "/api/WarehouseService/StorageUserLabelChange/ThirdStep",
}

export const getPagedList = (data) => {
  return client.request({
    url: api.getPagedList,
    data,
    method: "POST",
  });
};

export const getStorageUserLabelChange = (params) => {
  return client.request({
    url: api.getStorageUserLabelChange,
    params,
    method: "GET",
  });
};

export const cancelStorageUserLabelChange = (data) => {
  return client.request({
    url: api.cancelStorageUserLabelChange,
    data,
    method: "POST",
  });
};

export const getInventoryWarehouseWithAdress = (data) => {
  return client.request({
    url: api.getInventoryWarehouseWithAdress,
    data,
    method: "POST",
  });
};

export const getUserSelectInventoryListPaged = (data) => {
  return client.request({
    url: api.getUserSelectInventoryListPaged,
    data,
    method: "POST",
  });
};

export const createFirstStep = (data) => {
  return client.request({
    url: api.createFirstStep,
    data,
    method: "POST",
  });
};

export const getSecondStep = (params) => {
  return client.request({
    url: api.getSecondStep,
    params,
    method: "GET",
  });
};

export const createSecondStep = (data) => {
  return client.request({
    url: api.createSecondStep,
    data,
    method: "POST",
  });
};

export const getThirdStep = (params) => {
  return client.request({
    url: api.getThirdStep,
    params,
    method: "GET",
  });
};

export const getFee = (params) => {
  return client.request({
    url: api.getFee,
    params,
    method: "GET",
  });
};

export const createThirdStep = (data) => {
  return client.request({
    url: api.createThirdStep,
    data,
    method: "POST",
  });
};