<template>
  <Content>
    <template #contentTitle>{{ $t('menu.storage_label_change_detail') }}</template>
    <template #contentBody>
      <a-spin :spinning="loading">
        <a-row :gutter="[8, 8]" type="flex" justify="space-between">
          <a-col>
            <a-row :gutter="[8, 8]">
              <a-col :span="24">
                {{ $t('warehouse.belonging_to_warehouse') }}: 
                <span v-if="pageInfo.warehouse">{{ pageInfo.warehouse.warehouseNo }}</span>
                <span v-else>-</span>
              </a-col>
              <a-col :span="24">{{ $t('warehouse.label_task_name') }}: {{ pageInfo.taskName || '-' }}</a-col>
              <a-col :span="24">{{ $t('warehouse.tag_task_number') }}: {{ pageInfo.taskNo || '-' }}</a-col>
            </a-row>
          </a-col>
          <a-col :lg="6" :span="24">
            <a-row :gutter="[8, 8]">
              <a-col :span="24" style="text-align: center;">{{ $t('warehouse.task_status') }}</a-col>
              <a-col :span="24" style="text-align: center;">
                <a-typography-text type="secondary" v-if="pageInfo.status === labelTaskStatusEnum.cancelled">{{ $t($enumLangkey('labelTaskStatus', pageInfo.status)) }}</a-typography-text>
                <a-typography-text type="success" v-else>{{ $t($enumLangkey('labelTaskStatus', pageInfo.status)) }}</a-typography-text>
              </a-col>
              <a-col :span="24" style="text-align: center;">
                <a-button v-if="pageInfo.cancelled" @click="handleCancel">{{ $t('warehouse.cancel_task') }}</a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-table
          style="min-width: 850px; overflow-x: auto;"
          class="mt-3"
          size="small"
          :columns="columns"
          :data-source="pageInfo.items"
          :pagination="false"
          :row-key="(record) => record.detailId"
        >
          <template #serialNumber="{ index }">
            {{ index + 1 }}
          </template>
          <template #boxNo="{ record }">
            {{ record.box?.boxNo || '-' }}
            <a-tag v-if="record.isCancelled" class="ml-2">{{ $t('logistics.canceled') }}</a-tag>
          </template>
          <template #selfBoxNo="{ record }">
            {{ record.box?.selfBoxNo || '-' }}
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.items"
              :pagination="false"
              :row-key="(record) => record.productRlId"
            >
              <template #serialNumber="{ index }">
                {{ index + 1 }}
              </template>
              <template #productIdentifyImgs="{ record }">
                <a-row :gutter="8" v-if="record.productIdentifyImgs.length > 0">
                  <a-col v-for="(item, index) in record.productIdentifyImgs" :key="index">
                    <CFileDisplay :fileUrl="item" />
                  </a-col>
                </a-row>
                <span v-else>-</span>
              </template>
              <template #labelUrl="{ record }">
                <CFileDisplay :fileUrl="record.labelUrl" />
              </template>
              <template #newProductIdentify="{ record }">
                {{ record.newProductIdentify || '-' }}
              </template>
              <template #productCount="{ record }">
                {{ record.productCount || '-' }}
              </template>
              <template #actualProductCount="{ record }">
                {{ record.actualProductCount || '0' }}
              </template>
            </a-table>
          </template>
        </a-table>
        <a-row class="mt-3" :gutter="[16, 16]" type="flex" justify="end">
          <a-col v-if="pageInfo.fee">
            <CFee :fees="pageInfo.fee.fees" :totalFee="pageInfo.fee.totalFee" />
          </a-col>
        </a-row>
        <a-row class="mt-5 mt-3" type="flex" justify="end">
          <a-col :pull="2">
            <a-button type="primary" ghost @click="handleBack">{{ $t('common.backtrack') }}</a-button>
          </a-col>
        </a-row>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Button, Col, Row, Spin, Table, Modal, Popover, Typography, message, Tag } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CFileDisplay from '@/views/components/CFileDisplay.vue';
import CFee from '@/views/components/CFee.vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import { useTab } from "@/hooks/tabs/index";
import { setFormStateValue } from '@/utils/general.js';
import { labelTaskStatus as labelTaskStatusEnum } from '@/enum/enum.json';
import { getStorageUserLabelChange, cancelStorageUserLabelChange } from '@/api/modules/storage/labelChange.js';

export default defineComponent({
  name: 'storage_label_change_detail',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    APopover: Popover,
    ATypographyText: Typography.Text,
    ATag: Tag,
    Content,
    CFileDisplay,
    CFee,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    
    const state = reactive({
      taskId: null,
      loading: false,
      pageInfo: {
        cancelled: false,
        fee: null,
        items: [],
        status: null,
        warehouse: null,
        taskName: null,
        taskNo: null,
      },
    });

    const columns = [
      {
        width: 150,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        width: 350,
        title: () => vueI18n.t('warehouse.se_box_number'),
        slots: {
          customRender: 'boxNo',
        }
      },
      {
        width: 350,
        title: () => vueI18n.t('common.user_defined_box_no'),
        slots: {
          customRender: 'selfBoxNo',
        }
      },
    ];

    const innerColumns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        dataIndex: 'productIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.identifiable_product_information'),
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.recognizable_product_images'),
        slots: {
          customRender: 'productIdentifyImgs',
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_labeling_document'),
        slots: {
          customRender: 'labelUrl',
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_label'),
        slots: {
          customRender: 'newProductIdentify',
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
        slots: {
          customRender: 'productCount',
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.quantity_of_finished_products'),
        slots: {
          customRender: 'actualProductCount',
        }
      },
    ];

    const handleCancel = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return cancelStorageUserLabelChange({ taskId: state.taskId }).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            getDetail();
          });
        },
      });
    }

    const getDetail = () => {
      state.loading = true;
      getStorageUserLabelChange({ taskId: state.taskId }).then(({ result }) => {
        if (result) {
          setFormStateValue(state.pageInfo, result);
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "storage_label_change_list" });
    };

    onMounted(() => {
      let params = route.params;
      if ('id' in params) {
        state.taskId = params.id;
        getDetail();
      }
    });

    return {
      ...toRefs(state),
      labelTaskStatusEnum,
      columns,
      innerColumns,
      handleCancel,
      handleBack,
    }
  }
})
</script>

<style scoped>

</style>